import RichText from '@components/RichText/RichText';
import BrandBox from '@custom-components/BrandBox/BrandBox';
import { useLinkComponent } from '@link';
import { mapImage, wrap } from '@sitecore/common';
import { CardRebrandedRendering } from '@sitecore/types/CardRebranded';
import { Stack, ButtonLink, Box, Text, TextLink, Stretch, Image, Bleed, Grid } from '@sparky';

const CardRebranded: React.FC<CardRebrandedRendering> = ({ fields: { title, content, buttonLink, link, image } }) => {
  const Link = useLinkComponent();
  const imageProps = mapImage(image);

  return (
    <Box borderRadius="m">
      <Grid>
        <Grid.Item order={{ initial: 2 }}>
          {(title.value || title.editable) && (
            <Box paddingTop="6">
              <Grid columns={12}>
                <Grid.Item gridColumn={{ initial: '1/12', md: '1/12' }}>
                  <Bleed top="16">
                    <BrandBox title={title.value} borderRadius="right" size="S" />
                  </Bleed>
                </Grid.Item>
              </Grid>
            </Box>
          )}
        </Grid.Item>
        <Grid.Item order={{ initial: -1 }}>
          {imageProps.src && (
            <Image
              alt={imageProps.alt}
              aspectRatio="4/3"
              height={{ initial: 'auto' }}
              objectFit="cover"
              sizes={{ initial: '100%' }}
              src={imageProps.src ?? ''}
              srcSet={imageProps.srcSet ?? ''}
              width={{ initial: '100%' }}
              borderRadius={['rounded', 'rounded', 'rounded', 'square']}
            />
          )}
        </Grid.Item>
      </Grid>

      <Box paddingTop="6">
        <Stack gap="6">
          {(content.value || content.editable) && (
            <Text size={{ initial: 'BodyM', lg: 'BodyL' }}>{wrap(content, <RichText html={content.value} />)}</Text>
          )}

          {((buttonLink.value.href && buttonLink.value.text) || buttonLink.editable) && (
            <Stack.Item>
              <Stretch width={{ initial: true, md: false }}>
                <Link editable={buttonLink.editable} linkType={buttonLink.value.linktype} linkValue={buttonLink.value}>
                  <ButtonLink target={buttonLink.value.target}>{buttonLink.value.text}</ButtonLink>
                </Link>
              </Stretch>
            </Stack.Item>
          )}

          {((link.value.href && link.value.text) || link.editable) && (
            <Stack.Item>
              <Link editable={link.editable} linkType={link.value.linktype} linkValue={link.value}>
                <TextLink emphasis="high" target={link.value.target}>
                  {link.value.text}
                </TextLink>
              </Link>
            </Stack.Item>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default CardRebranded;
